<template>
  <div class="meal" v-if="meal">
    <div class="meal-image">
      <img class="img-fluid" v-if="meal.image" :src="meal.image"/>
      <img class="img-fluid" v-else src="@/assets/no-image.jpg" />
    </div>
    <div class="meal-detail">
      <div class="meal-name" v-if="langcode === 'en'">
        {{ meal.name }}
      </div>
      <div class="meal-name" v-if="langcode === 'zh'">
        {{ meal.name }}
      </div>
      <div class="meal-price">
        HK$ {{ meal.regular_price  }}
      </div>
      <div class="action-button-wrapper">
        <button class="btn btn-primary" @click="addItem(meal)">{{ $t('Add To Order')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'ProductBox',
  props: ['meal'],
  mounted(){
    this.selectedVariations = this.meal.variation_options;
  },
  methods: {
    addItem(meal){
      if(meal.had_variation){
        if(!this._checkOptionSelected(this.selectedVariations)){
          this.$message({
            message: this.$t('You should select all option of variation before add to the order.'),
            type: 'error'
          });
          return;
        }
        meal.variation_options = this.selectedVariations;
      }
      this.addOrderItem(meal);
    },
    _checkOptionSelected(variations){
      for(let i = 0; i < variations.length; i++){
        const variation = variations[i];
        if(!variation.option){
          return false;
        }
      }
      return true;
    },
    getProductCategoires(meal){
      const mealCategories = [];
      for(let i = 0; i < meal.meal_categories.length; i++){
        const mealCategorie = meal.meal_categories[i];
        if(mealCategorie){
          if(this.langcode === 'zh'){
            mealCategories.push(mealCategorie.categorie_name_zh);
          }else{
            mealCategories.push(mealCategorie.categorie_name_en);
          }
        }
      }
      const mealCategoriesString = mealCategories.join(', ');
      if(!mealCategoriesString){
        return '';
      }
      return mealCategoriesString;
    },
    updateVariation(e, variation) {
      const optionName = e.target.value;
      const targetOption = variation.options.find(function(item){
        return item.option_name_en === optionName || item.option_name_zh === optionName;
      });
      const targetVariationIndex = this.selectedVariations.findIndex(function(item){
        return item.variation_name_en === variation.variation_name_en && item.variation_name_zh === variation.variation_name_zh;
      });

      if(targetVariationIndex !== -1){
        this.selectedVariations[targetVariationIndex].option = targetOption;
      }
    },

    getProductImage(imageField){
      if(imageField){
        const images = JSON.parse(imageField);
        const image = images[0];
        return image;
      }
    },
    ...mapActions(['addOrderItem']),
  },
  data(){
    return {
      selectedVariations: [],
    }
  },
  computed: mapState({
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style scoped lang="scss">
.meal{
  margin-bottom: 20px;
}
.meal-image{
  height: 150px;
  width: 150px;
  img{
    max-width: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.meal-name{
  margin-top: 10px;
  font-weight: bold;
}
.meal-price{
  margin-top: 10px;
}
.meal-variation{
  display: flex;
  margin-top: 10px;

  .variation-label{
    margin-right: 10px;
  }
}
.action-button-wrapper{
  margin-top: 10px;
}
</style>
