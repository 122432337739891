<template>
  <div class="meals">
      <ItemFilter :placeholder="$t('Search by meal name')" default="mealName" @search="searchItems">
        <el-option :label="$t('Meal Name Search')" value="mealName"></el-option>
      </ItemFilter>
      <div class="categories-filter">
        <span class="filter-label"> {{ $t('Categories') }}</span>
        <el-checkbox-group v-model="targetCategories" @change="onCheckboxChange">
          <el-checkbox v-if="langcode == 'zh'" :key="categorie.id" v-for="categorie in categories" :label="categorie.categorie_name_zh"></el-checkbox>
          <el-checkbox v-if="langcode == 'en'" :key="categorie.id" v-for="categorie in categories" :label="categorie.categorie_name_en"></el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="meal-list">
        <div class="row">
          <div class="col-md-3" v-for="meal in meals">
            <MealBox :meal="meal"/>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Menu from '@/lib/menu';
import ItemFilter from '@/components/Filter.vue';
import MealBox from '@/components/menu/MealBox.vue';

export default {
  name: 'MealList',
  components: {
    ItemFilter,
    MealBox,
  },
  mounted(){
    this.loadMeals();
    this.loadCategories();
  },
  methods: {
    searchItems(form){
      const searchField = {};
      let newItems = [];
      switch(form.filterTarget){
        case 'mealName':
        if(this.langcode === 'zh'){
          searchField['name_zh'] = form.filterValue;
        }else{
          searchField['name_en'] = form.filterValue;
        }
        newItems = Common.filterItems(searchField, this.orignalMeals);
        this.searchMeals = newItems;
        this.meals = newItems;
        break;
      }
    },
    onCheckboxChange(){
      const self = this; //Use self to replace this in filter function
      const targetCategories = self.targetCategories;
      const newItems = self.orignalMeals.filter(function(item){
        let itemName = '';
        const itemCateogires = item.meal_categories;
        for(let i = 0; i < itemCateogires.length; i++){
          const itemCateogire = itemCateogires[i];
          if(self.langcode === 'zh'){
            if(targetCategories.includes(itemCateogire.categorie_name_zh)){
              return true;
            }
          }else{
            if(targetCategories.includes(itemCateogire.categorie_name_en)){
              return true;
            }
          }
        }
      });
      self.meals = newItems;
      //If do not had any selected categoires ,reset it
      if(targetCategories.length === 0 ){
        self.meals = self.orignalMeals;
      }
    },
    async loadMeals(){
      try{
        const loginInfo = Common.getLoginInfo();
        let meals = await Menu.loadAllMeals(this.apiUrl, loginInfo);
        this.orignalMeals = meals;
        this.meals = meals;
      }catch(err){
        console.log(err);
      }
    },
    async loadCategories(){
      const loginInfo = Common.getLoginInfo();
      try{
        const categories = await Menu.loadAllCategories(this.apiUrl, loginInfo);
        this.categories = categories;
      }catch(err){
        console.log(err);
      }
    },
    ...mapActions(['addOrderItem']),
  },
  data(){
    return {
      meals: [],
      orignalMeals: [],
      categories: [],
      targetCategories: [],
    }
  },
  computed: mapState({
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.categories-filter{
  padding-left: 20px;
  font-weight: bold;
  margin-bottom: 50px;
  display: flex;
  .filter-label{
    margin-right: 20px;
  }
}

.meal-list{
  padding-left: 20px;
  .meal{
    margin-bottom: 20px;
  }
  .meal-image{
    height: 150px;
    width: 150px;
    img{
      max-width: 100%;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .meal-name{
    margin-top: 10px;
    font-weight: bold;
  }
  .meal-price{
    margin-top: 10px;
  }
  .meal-variation{
    display: flex;
    margin-top: 10px;

    .variation-label{
      margin-right: 10px;
    }
  }
  .action-button-wrapper{
    margin-top: 10px;
  }
}


</style>
